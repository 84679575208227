import DemoFormHubspot from "../components/DemoPage/DemoFormHubspot/DemoFormHubspot";
import { graphql } from "gatsby";
import React, { useEffect } from "react";
import BottomCTA, { BottomCTAVariant } from "../components/BottomCta";
import NavigationBarSpacer from "../components/NavigationBarSpacer";
import SEO from "../components/Seo";
import Layout from "../containers/Layout";
import { useSegment, trackView } from "../utils/analytics";
import { Name, Type, Location, CTA_ID } from "../utils/analytics/constants";
import GrowthSection from "../components/DemoPage/GrowthSection/GrowthSection";
import Brands, { BrandObject } from "../components/Brands";
import Beekeepers from "../components/Brands/BrandSvgs/beekeepers.svg";
import Crocs from "../components/Brands/BrandSvgs/crocs.svg";
import FourSigmatic from "../components/Brands/BrandSvgs/foursigmatic.svg";
import Franklin from "../components/Brands/BrandSvgs/franklin.svg";
import HeroCosmetics from "../components/Brands/BrandSvgs/hero-cosmetics.svg";
import Huggies from "../components/Brands/BrandSvgs/huggies.svg";
import Kleenex from "../components/Brands/BrandSvgs/kleenex.svg";
import Mikes from "../components/Brands/BrandSvgs/mikes.svg";
import BackgroundImage from "gatsby-background-image";
import Section from "../containers/Section";
import { HeaderVariant } from "../components/Header";
import { chiliPiperAdd } from "../utils/chilipiper";
import { GatsbyImage } from "gatsby-plugin-image";
import GradientTitle from "../components/GradientTitle";
import FooterQRCode from "../components/FooterQRCode";

interface DemoPageProps {
  data: any;
  pageContext: any;
}

const DemoPage: React.FC<DemoPageProps> = props => {
  const { data, pageContext } = props;
  const { segment } = useSegment();

  const pageData = data?.allContentfulDemoPage?.nodes[0];

  const brandsList: BrandObject[] = [
    { src: <Crocs />, filter: true },
    { src: <Kleenex />, filter: true },
    { src: <Franklin />, filter: true },
    { src: <Huggies />, filter: true },
    { src: <FourSigmatic />, filter: true },
    { src: <Beekeepers />, filter: true },
    { src: <HeroCosmetics />, filter: true },
    { src: <Mikes />, filter: true }
  ];

  useEffect(() => {
    !pageContext.language.includes("zh") && chiliPiperAdd();
  }, []);

  return (
    <Layout
      primaryButtonId={CTA_ID.getStartedNav.id}
      secondaryButtonId={CTA_ID.signinNav.id}
    >
      <div className="bg-gray-5">
        <SEO
          title={pageData?.seoSettings?.metadataTitle}
          description={pageData?.seoSettings?.metadataDescription}
          image={pageData?.seoSettings?.openGraphImage?.url}
          robots={pageData?.seoSettings?.robots}
        />
        <NavigationBarSpacer />
        <div className="relative pt-32">
          <Section>
            <div className="grid flex-col items-top justify-center pb-0 lg:grid-cols-4 lg:pb-32">
              <div className="order-1 lg:order-none mb-12 col-span lg:col-span-4 lg:pr-1/2">
                {pageData?.demoPageGradientTitle ? (
                  <GradientTitle
                    gradientPosition={pageData?.demoPageGradientTitlePosition}
                    className="text-5xl lg:text-7xl font-semibold lg:text-left text-center md:font-bold"
                    color="purple"
                    variant={HeaderVariant.h1}
                    title={pageData?.demoPageGradientTitle}
                    lineBreak={false}
                  />
                ) : (
                  ""
                )}
              </div>
              <div className="order-3 py-20 lg:order-none lg:col-span-2 lg:sticky lg:top-32">
                {pageData?.iconTextDescription ? (
                  <GrowthSection features={pageData?.iconTextDescription} />
                ) : (
                  ""
                )}
              </div>
              <div className="relative order-2 lg:order-none w-full lg:col-span-2 lg:row-span-2">
                {/* TODO: ask Chris for full circle */}
                <DemoFormHubspot />
              </div>
            </div>
          </Section>

          <div className="relative bg-gray-6 rounded-tl-large rounded-br-large pb-32">
            <Section>
              <div className="flex lg:flex-row flex-col py-20 lg:py-36 items-center">
                <div className="w-full lg:w-1/2 lg:mr-10 md:pb-0 pb-[120px]">
                  <BackgroundImage
                    fixed={data?.ecommerce?.childImageSharp?.gatsbyImageData}
                    style={{
                      width: "100%",
                      height: "50%",
                      borderTopRightRadius: "6px",
                      borderBottomLeftRadius: "6px",
                      borderTopLeftRadius: "120px",
                      borderBottomRightRadius: "120px",
                      overflow: "hidden"
                    }}
                  >
                    <div
                      style={{
                        background: `linear-gradient(to right, rgba(66,165,245,1), rgba(66,165,245,0.8))`,
                        width: "100%",
                        height: "100%",
                        alignItems: "flex-start",
                        padding: "52px",
                        overflow: "hidden"
                      }}
                    >
                      <GatsbyImage
                        image={
                          pageData && pageData?.blueQuoteLogo?.gatsbyImageData
                        }
                        className="w-[162px] h-[39px]"
                        alt=""
                      />
                      <p className="text-2xl font-semibold text-white pt-14">
                        {pageData &&
                          pageData?.blueQuoteDescription?.blueQuoteDescription}
                      </p>
                      <div className="flex justify-start items-center pt-14">
                        <GatsbyImage
                          image={
                            pageData &&
                            pageData?.blueQuotePersonImage?.gatsbyImageData
                          }
                          alt=""
                          className="h-[48px] w-[48px]"
                        />
                        <p className="text-base font-medium text-white px-4">
                          <span className="font-semibold text-lg">
                            {pageData?.blueQuotePerson}
                          </span>
                          <p>{pageData?.blueQuotePersonRole}</p>
                        </p>
                      </div>
                    </div>
                  </BackgroundImage>
                </div>
                <Brands
                  className="lg:grid lg:grid-cols-4 lg:gap-x-[40px] lg:justify-between"
                  brandsList={brandsList}
                />
              </div>
            </Section>
            <BottomCTA
              variant={BottomCTAVariant.secondary}
              title={pageData?.bottomCta?.title}
              subtitle={pageData?.bottomCta?.subtitle}
              secondaryButtonText={pageData?.bottomCta?.secondaryButtonText}
              primaryButtonClassName={
                pageData?.bottomCta?.primaryButtonClassName
              }
              secondaryButtonClassName={
                pageData?.bottomCta?.secondaryButtonClassName
              }
              secondaryButtonOnClick={() => {
                trackView(Name.Cta, {
                  type: Type.Button,
                  text: pageData?.bottomCta?.secondaryButtonText,
                  page_url: window?.location.href,
                  location: Location.BottomCta
                });
                window.scrollTo({
                  top: 110,
                  left: 0,
                  behavior: "smooth"
                });
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DemoPage;

export const data = graphql`
  query ($language: String) {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allContentfulDemoPage(filter: { node_locale: { eq: $language } }) {
      nodes {
        seoSettings {
          metadataTitle
          metadataDescription
          openGraphImage {
            url
          }
          canonicalUrl
          robots
        }
        demoPageGradientTitle
        demoPageGradientTitlePosition
        iconTextDescription {
          title
          description {
            description
          }
          icon {
            url
          }
        }
        blueQuoteDescription {
          blueQuoteDescription
        }
        blueQuoteLogo {
          url
          gatsbyImageData
        }
        blueQuotePerson
        blueQuotePersonRole
        blueQuotePersonImage {
          gatsbyImageData
          url
        }
        bottomCta {
          title
          subtitle
          primaryButtonId
          secondaryButtonId
          primaryButtonText
          secondaryButtonText
          primaryButtonClassName
          secondaryButtonClassName
        }
      }
    }
    ecommerce: file(relativePath: { eq: "Agencies/ecommerce.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    qrCodes: allContentfulFooterQrCode(filter: { node_locale: { eq: "zh-CN" } }) {
      nodes {
        title
        node_locale
        qrCode {
          url
        }
      }
    }
  }
`;
