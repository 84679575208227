import React from "react";
import useMedia from "use-media";

interface GrowthSectionProps {
  features?: any;
}

const GrowthSection: React.FC<GrowthSectionProps> = props => {
  const { features } = props;
  const isDesktop = useMedia({ minWidth: "1024px" });
  return (
    <div className="w-full">
      <div className="flex lg:flex-col md:flex-row flex-col">
        {features &&
          features?.map((feature, index) => {
            return (
              <div
                key={index}
                className="flex lg:flex-row flex-col text-center lg:text-left mb-16"
              >
                <div
                  style={{ height: "fit-content", width: "fit-content" }}
                  className="rounded-full inline-block bg-white p-3 shadow-xl lg:mx-0 mx-auto mb-5"
                >
                  <img
                    width="33"
                    height="32"
                    src={feature && feature?.icon?.url}
                    alt={feature?.description}
                  />
                </div>
                <div className="lg:ml-6 lg:mx-0 md:ml-6 md:mx-0 mx-20 lg:w-2/3">
                  <p className="text-black font-semibold text-2xl pb-1">
                    {feature?.title}
                  </p>
                  <p>{feature?.description?.description}</p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};
export default GrowthSection;
